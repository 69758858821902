<template>

  <div class="relative flex h-16 items-center justify-between">
    <router-link to="/">
      <img v-if="currentPage.name == 'Home'" src="@/assets/logo-title.svg" class="md:invisible md:h-0 md:w-0 pl-4 h-5 my-auto " alt="Logo and name">
      <img v-else src="@/assets/logo-title.svg" class="justify-end pl-4 h-5 my-auto " alt="Logo and name">
    </router-link>

    <nav id="nav" class="flex justify-end space-x-4 pr-4 py-3">
      <router-link to="/">Home</router-link>
      <router-link to="/about">About</router-link>
      <a style="color: #FA9483" href="https://donate.stripe.com/8wMaI4ccI3At9uE288">Donate</a>
    </nav>
  </div>

  <router-view/>

</template>

<script>
import router from './router';

export default {
  data() {
    return {
      currentPage: router.currentRoute,
    }
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2d4057;
}

#nav a {
  color: #2d4057;
}

</style>
