<template>
  <div class="px-5 pb-2 mx-auto max-w-3xl">
    <img class="mx-auto h-0 md:h-12 md:my-3" alt="Logo and name" src="@/assets/logo-title.svg" >
    <div class="mx-auto mt-1 relative rounded-md shadow w-11/12 max-w-xl md:max-w-11/12">
      <input
        v-model="searchField"
        @input="limitSearchLength"
        @keydown.enter="$event.target.blur()"
        @keydown.esc="$event.target.blur()"
        type="text"
        name="ipsearch"
        id="ipsearch"
        class="text-center block mx-auto
              focus:outline-none w-full
              sm:text-sm border-gray-300 rounded-md
              focus:placeholder-transparent h-9" placeholder="IP Search">
    </div>
    <br>
    <IpGrid :search="searchField"/>
  </div>
</template>

<script>
import IpGrid from '@/components/IPGrid.vue'
export default {
  name: 'Home',
  components: {
    IpGrid
  },
  data() {
    return {
      searchField: '',
      searchLengthMax: 70
    }
  },
  methods: {
    limitSearchLength(event) {
      console.log(`Search: ${this.searchField}`)
      if(event.target.value.length > this.searchLengthMax){
        this.searchField = this.searchField.slice(0, this.searchLengthMax)
      }
    }
  },
}
</script>
