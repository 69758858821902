<template>
    <button class="focus:outline-none h-auto cursor-pointer p-3 md:p-8 w-full
                   mx-auto transition duration-300 ease-in-out bg-white
                   rounded-xl shadow-md hover:shadow-lg active:shadow-inner
                   overflow-hidden min-w-sm"
            @click="goToIP">
        <div class="w-3/4 mx-auto">
            <img class="object-contain max-h-14 mx-auto m-3" :src="getSymbol()" :alt="name">
        </div>
        <div class="space-y-0.5">
            <p class="text-sm md:text-lg text-black font-semibold">
                {{ name }}
            </p>
            <p class="text-gray-500 text-xs ">
                {{ description }}
            </p>
            </div>
    </button>
</template>

<script>
export default {
    name: 'IPCard',
    props: {
        name: String,
        description: String,
    },
    methods: {
        goToIP() {
            console.log('Route taken: /ip/' + this.name.toLowerCase())
            this.$router.push('/ip/' + this.name.toLowerCase())
        },
        getSymbol() {
            try {
                return require(`@/assets/images/symbols/${this.name.toLowerCase()}.png`)
            } catch (error) {
                console.log(error)
                return require(`@/assets/images/symbols/inverter.png`)
            }

        }
    }
}
</script>
