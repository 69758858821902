import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './design/tailwind.css'
import VueGtag from "vue-gtag"

const app = createApp(App)
app.use(router)
app.use(VueGtag, {
    appName: "Torpor",
    pageTrackerScreenviewEnabled: true,
    config: { id: "G-DS9DFJKW0V"},
}, router)

app.mount('#app')
