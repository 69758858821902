<template>
  <div class="grid mx-auto max-w-xl md:max-w-none grid-cols-3 md:grid-cols-4 gap-4">
    <div v-for="ip in ipcores[search]" :key="ip">
      <IPCard :name="ip.name" :description="ip.mini_desc" />
    </div>
  </div>
</template>

<script>
import IPCard from '@/components/IPCard.vue'
import axios from 'axios'

export default {
  name: 'IpGrid',
  props: {
    search: String,
  },
  components: {
    IPCard
  },
  data() {
    return {
      ipcores: [],
    }
  },
  watch: {
    search: {
      handler: "getIP",
      immediate: true
    }
  },
  methods: {
    getIP(newSearch, ) {
      if(this.ipcores[newSearch] == undefined && newSearch.length < 256) {
        axios
          .get(`https://api.torporip.com/?search=${this.search}`)
          .then(response => (this.ipcores[newSearch] = response.data))
      }
    }
  },
}
</script>